(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name entitats.entitat.controller:EntitatCtrl
   *
   * @description
   *
   */
  angular
  .module('neo.home.entitats.entitat')
  .controller('EntitatCtrl', EntitatCtrl);

  function EntitatCtrl() {
    var vm = this;
    vm.ctrlName = 'EntitatCtrl';
    vm.currentNavItem = 'informacio';
  }
}());
